import React from 'react';
import TrainingRoomJPG from '@assets/images/training-room.jpg';
import TrainingRoomMobileJPG from '@assets/images/training-room-mobile.jpg';
// eslint-disable-next-line import/no-cycle
import SportsMain from '~/components/organisms/SportsMain/SportsMain';
import HeaderFooterLayout from '~/components/molecules/HeaderFooterLayout/HeaderFooterLayout';

const SportsPage: React.FC = () => {
  return (
    <>
      <HeaderFooterLayout
        desktopHero={TrainingRoomJPG}
        mobileHero={TrainingRoomMobileJPG}
        altHero="Training"
      >
        <SportsMain />
      </HeaderFooterLayout>
    </>
  );
};

export default SportsPage;
